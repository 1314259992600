<template>
    <div>
        <card-header title="Question Responses" icon="fa-poll-people"/>

        <card-body>
            <card-list>
                <question-list-item
                    :card="card"
                    :question="question"
                    no-sort
                />
                <subheader-list-item title="Responses" icon="fas fa-poll-people"/>

                <trip-response-list-item
                    :card="card"
                    :response="r"
                    v-for="r in question.responses"
                    :active="(child && child.definition.component === 'edit-response') && child.props.responseId === r.id.toString()"
                    @click="$openCard('edit-response', {registrationId: r.registration_id, responseId: r.id}, card)"
                    show-user
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import TripListItem from "../../components/TripListItem";

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import TripResponseListItem from "@/components/TripResponseListItem";
    import QuestionListItem from "@/components/QuestionListItem";

    export default {
        props: ['card', 'child', 'props'],
        components: {QuestionListItem, TripResponseListItem, CardBody, TripListItem, SubheaderListItem, CardList, CardHeader},
        data: function() {
            return {
                question: {},
                trip: {}
            }
        },
        methods: {
            loadQuestion: function (force) {
                this.$emit('loading', true);
                http.get('/api/questions/' + this.props.questionId, {force}).then(response => {
                    this.question = response.data;
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadQuestion(true);
            }
        },
        watch: {
            'props.questionId': function() {
                this.loadQuestion();
            }
        },
        mounted() {
            this.loadQuestion();
        }
    };
</script>

<style lang="scss" scoped>

</style>
